import contentScreenshot from '../media/screenshots/contentScreenshot.png'

export default function ContentSection({ contentRef }) {
    return (
      <div className="bg-white py-8 lg:py-16" ref={contentRef}>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">

            <h1 className="text-2xl lg:text-5xl font-bold tracking-tight text-orange-600">
                Meant for those who want to move well, move right, and perform to their best potential
            </h1>
            
            <div className="mt-0 lg:mt-16 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
            
              {/* Left Hand Side */}
              <div>
                <img
                    className="mt-7 shadow-2xl"
                    style={{ width: 575 }}
                    src={contentScreenshot}
                    alt="Programming"
                />
              </div>
              
              {/* Right Hand Side */}
              <div className="space-y-8 text-lg">

                <p>
                    Programs cover EVERYTHING from a holistic approach. Mobility, motor control, muscle - tendon capacity, 
                    multi-system endurance, strength, power plyometrics and injury prevention.
                </p>

                <p>
                    WhichWeight dives much deeper than just raw strength. We look at optimizing your range of motion 
                    and body control, while still adding strength on top of that!
                </p>

                <p>
                    This system has been used with Olympic athletes with great success.
                    All exercises were carefully tested for our endurance athlete training system. These are the exercises 
                    PROVEN to be effective for you! Kinesis Integrated use these ON THE DAILY in their performance studio.
                </p>

                <p>
                    The workouts are broken up into specific phases of training. The beginning will set up your body to 
                    move well as you transition into more intense exercises and loads!
                </p>

              </div>

            </div>

          </div>
        </div>
        
      </div>
    )
  }
  