import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import Header from "../components/Header";
import Footer from "../components/Footer";

import ImageCarousel from "../components/ImageCarousel";
import ContentSection from "../components/ContentSection";
import Testimonials from "../components/Testimonials";
import '../css/react-phone-number-input/style.css';

export default function LandingPage() {

    // Function used to navigate to new pages
    let navigate = useNavigate();
    const OnNavigationButtonPressed = (route, props) => { navigate(route, props); };

    return (
        <div className="bg-white">
        
        <Header />

        <div className="relative isolate pt-4 lg:pt-0">

            {/* Background Grid Pattern */}
            <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
            >
            <defs>
                <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
                >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
                />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
            </svg>
            
            {/* Content */}
            <div className="mx-auto max-w-7xl px-4 lg:px-6 lg:py-16 sm:py-16 lg:flex lg:items-center lg:gap-x-20 lg:px-8">
            
            {/* Left Hand Side */}
            <div className="mx-auto space-y-4 lg:space-y-8 max-w-3xl mb-8 lg:mx-0 lg:flex-auto">
                
                <div className="space-y-4">
                
                {/* Gradient Text */}
                <h1 className="text-center lg:text-left text-3xl lg:text-7xl lg:pb-2 font-bold text-gray-900 tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-orange-600 to-orange-400">
                    Olympian-Powered <br />
                    Strength Programming <br />
                    Adapted to You
                </h1>

                {/* Details Text */}
                <p className="text-center lg:text-left text-sm lg:text-lg leading-6 lg:leading-8 text-gray-600">
                    With WhichWeight, strength training transcends the elite; it's for everyone. WhichWeight adapts Olympian-trusted 
                    training methods to fit your unique needs. Our adaptive algorithm intelligently analyzes your profile to set an accurate, 
                    safe starting point and scales with your progress. Endorsed by champions and designed for all—because every dream, especially 
                    yours, deserves to be great. Start with WhichWeight and confidently lift towards your aspirations.
                </p>

                {/* Now Available Button (Small Screen) */}
                <button 
                    className="mx-auto block sm:hidden px-8 py-2 bg-gray-200 rounded-full w-full bg-gradient-to-r from-orange-600 to-orange-400" 
                    onClick={() => OnNavigationButtonPressed('/getapp')}
                >
                    <div className="flex items-center gap-x-2 justify-center text-white font-bold">
                    Now Available
                    <ArrowRightIcon className="mt-0.5 h-4" />
                    </div>
                </button>

                </div>

                {/* Now Available Button (Large Screen) */}
                <button 
                    className="hidden sm:block w-2/3 py-3 bg-gray-200 rounded-full bg-gradient-to-r from-orange-600 to-orange-400" 
                    onClick={() => OnNavigationButtonPressed('/getapp')}
                >
                <div className="flex justify-center items-center gap-x-2 text-lg text-white font-bold">
                    Now Available
                    <ArrowRightIcon className="mt-0.5 h-6" />
                </div>
                </button>

            </div>

            {/* Right Hand Side (Large Screens) */}
            <div className="hidden sm:block sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                <ImageCarousel />
            </div>

            </div>

            {/* App Screenshot (Small Screens) */}
            <div className="block sm:hidden mt-4 mb-4 flex-shrink-0 flex-grow">
                <ImageCarousel />
            </div>          
            
            {/* Content Section */}
            <ContentSection />

            {/* Testimonials */}
            <Testimonials />

        </div>

        <Footer />

        </div>
    )
}