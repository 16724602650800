import { BrowserRouter, Routes, Route } from "react-router-dom";

import LandingPage from "./views/LandingPage";

import AboutUs from "./views/AboutUs";

import GetApp from "./views/GetApp";

import TermsOfService from "./views/TermsOfService";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Wavier from "./views/Wavier";

import { ModalProvider } from "./components/Modals/ModalContext";
import ModalOverlay from "./components/Modals/ModalOverlay";

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <ModalProvider>

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<LandingPage />} />

          <Route path="/about" element={<AboutUs />} />

          <Route path="/getapp" element={<GetApp />} />

          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/wavier" element={<Wavier />} />

        </Routes>

        <ModalOverlay />
        
      </ModalProvider>
    </BrowserRouter>
  );
}

export default App;
