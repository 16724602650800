import iconLogo from '../media/logos/logo192.png';

import Header from "../components/Header";
import Link from '../components/Link';

export default function PrivacyPolicy() {

    return(
        <div className="bg-white">

            <Header />

            {/* Content */}
            <div className="mx-auto max-w-7xl px-4 lg:px-6 my-8 lg:border-2 lg:rounded-2xl">

                <img className="mx-auto w-32 lg:w-60" src={iconLogo} alt="WhichWeight Icon Logo" />

                <div className="text-center text-lg lg:text-2xl font-bold">WhichWeight Privacy Policy</div>
                <div className="text-center lg:text-xl italic">Last Modified February 13, 2024</div>

                <div className='my-4 lg:my-8 space-y-4'>

                    <div className='underline text-lg'><strong>Summary</strong></div>
                    
                    <div>This Privacy Policy ("Privacy Policy") for WhichWeight, LCC (“WhichWeight,” “us,” or “we”) discloses the privacy practices for <Link url='https://www.whichweight.com'/>, a website (the "Site" or "Website") owned and operated by WhichWeight and any services provided by us. As part of the normal operation of WhichWeight's Services, we collect and use personal information about you.</div>
                    <div>• Consent. This Privacy Policy describes the information we collect about you and what may happen to that information. By accepting the Terms of Use, you expressly consent to our use and disclosure of your personally identifiable information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the terms of the WhichWeight's Terms of Use at <Link url='https://www.whichweight.com/terms'/>. References to “Services” in this Privacy Policy refer to the services described in our Terms of Use.</div>
                    <div>• Personal Data We May Collect About You. We collect information to provide services to you, to help improve your customer experience, and to support our business functions including our training algorithms. We may collect the following categories of consumer personal data:</div>
                    <div>• Identifiers: Information that identifies, relates to, describes, or is capable of being associated with, a particular individual such as name, email address, telephone number.</div>
                    <div>• Commercial information: Purchase and transaction history.</div>
                    <div>• Internet or other electronic network activity information: Mobile device and online identifiers, Mac address, IP address, cookie IDs, browser activity, and information regarding your interaction with our website or mobile application.</div>
                    
                    <div>Demographic information. Age, gender, date of birth, height, weight, prior and present exercise and sport activity, and weight lifting experience.</div>
                    <div>• Profile information. Any form of automated process performed on personal data to evaluate, analyze, or predict personal aspects related to an identified or identifiable individual's health or exercise ability.</div>
                    <div>When you sign up for a subscription with us, any credit card information you provide is collected and processed directly by our payment processor, Stripe, Inc., through their checkout service. We never receive or store your full credit card information.</div>
                    <div>• How We Use Your Personal Information. </div>
                    <div>• We use your demographic information and profile information for training our adaptive algorithm and improving our Services.</div>
                    <div>• We use your identifiers and profile information to notify you about changes to our Website or any products or services we offer or provide though it.</div>
                    <div>• We use your identifiers and commercial information to provide you with notices about your account or subscription, including expiration and renewal notices.</div>
                    <div>• We use your internet or other electronic network activity information for improving our internal operations, securing our systems, and detecting fraudulent or illegal activity.</div>
                    <div>• We use your identifiers, demographic information, and profile information for marketing purposes, including emails about new services, offers, promotions, and other information about our Services.</div>
                    <div>• We use your personal information for compliance with legal obligations, including cooperating with government authorities, courts, or regulators in accordance with the legal obligations under applicable laws to the extent this requires the processing or disclosure of personal data to protect our rights, or otherwise necessary for our legitimate interest in protecting against misuse or abuse of our website or Services, protecting personal property or safety, pursuing remedies available to us and limiting our damages, complying with judicial proceedings, court orders or legal processes, responding to lawful requests, or for auditing purposes.</div>
                    <div>• We may use all other information in any other way we may describe when you provide the information.</div>
                    <div>• Disclosure of Your Information. We may disclose aggregated information about our users without restriction. </div>
                    
                    <div>We may disclose personal information that we collect or you provide as described in this privacy policy:</div>
                    <div>• To our subsidiaries and affiliates.</div>
                    <div>• To contractors, service providers, and other third parties we use to support our business.</div>
                    <div>• To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of WhichWeight's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by WhichWeight about our Website users is among the assets transferred.</div>
                    
                    <div>We may also disclose your personal information:</div>
                    <div>• To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</div>
                    <div>• To enforce or apply our Terms of Use at <Link url='https://www.whichweight.com/terms'/> and other agreements, including for billing and collection purposes.</div>
                    <div>• If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of WhichWeight, our customers, or others.</div>
                    <div>• Data Security. We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. </div>
                    
                    <div>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. </div>
                    <div>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.</div>
                    <div>• Children Under the Age of 13. Our Website is not intended for children under 13 years of age. No one under age 13 may provide any personal information to the Website. We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on this Website, register on the Website, make any purchases through the Website, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or username you may use. If you believe we might have any information from or about a child under 13, please contact us at support@whichweight.com.</div>
                    
                    <div>Residents of certain states under 13, 16, or 18 years of age may have additional rights regarding the collection and sale of their personal information. Please see your state privacy rights for more information.</div>
                    <div>• Changes to Our Privacy Policy. It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will notify you by email to the email address specified in your account or through a notice on the Website home page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes.</div>
                    <div>• Notice of Privacy Rights. WhichWeight’s obligations under these laws are limited to the extent we collect or process your personal information and to the extent WhichWeight is subject to a given privacy law. As a small business, WhichWeight may be exempt from certain U.S. state privacy laws. Please contact support@whichweight.com if you have any questions.</div>
                    
                    <div>You may have the right to:</div>
                    <div>• Opt-out of the processing of your personal data for purpose of targeted advertising.</div>
                    <div>• Non-discrimination for exercising any of these rights.</div>
                    <div>• Appeal if your request is denied.</div>
                    
                    <div>If you wish to exercise your privacy rights beyond the methods described above, or if you want to express concerns, lodge a complaint, or request information, please submit a request by emailing us at support@whichweight.com. If you choose to contact us directly, you will need to provide us with (a) enough information to identify you, and (b) a description of what right you want to exercise and the information to which your request relates. We are not obligated to make a data access or data portability disclosure if we cannot verify that the person making the request is the person about whom we collected information or is someone authorized to act on such person's behalf. Any personal data we collect from you to verify your identity in connection with you request will be used solely for the purposes of verification.</div>
                
                </div>

            </div>

        </div>
    );

}