import olympicRings from '../media/logos/Olympic_Rings.svg';

import morganPearsonPic from '../media/profile_pics/testiomonials/morgan_pearson.png';
import karaGoucherPic from '../media/profile_pics/testiomonials/kara_goucher.png';
import ruthWinderPic from '../media/profile_pics/testiomonials/ruth_winder.png';
import adamGoucherPic from '../media/profile_pics/testiomonials/adam_goucher.png';
import vittoriaLopesPic from '../media/profile_pics/testiomonials/vittoria_lopes.png';
import saraVaughnPic from '../media/profile_pics/testiomonials/sara_vaughn.png';
import drewHunterPic from '../media/profile_pics/testiomonials/drew_hunter.png';
import lauraThweattPic from '../media/profile_pics/testiomonials/laura_thweatt.png';
import samAppletonPic from '../media/profile_pics/testiomonials/sam_appleton.png';
import rudyVonBergPic from '../media/profile_pics/testiomonials/rudy_von_berg.png';
import taylorSpiveyPic from '../media/profile_pics/testiomonials/taylor_spivey.png';
import eliHemmingPic from '../media/profile_pics/testiomonials/eli_hemming.png';
import abbyHallPic from '../media/profile_pics/testiomonials/abby_hall.png';
import reedFischerPic from '../media/profile_pics/testiomonials/reed_fischer.png';

export default function Testimonials() {
    
    const testimonials = [
        { 
            name: "Morgan Pearson",
            olympian: true,
            accomplishment: "Tokyo 2021 - Trialthon Mixed Relay Silver Medalist",
            src: morganPearsonPic
        },
        { 
            name: "Kara Goucher",
            olympian: true,
            accomplishment: "Beijing 2008 and London 2012 - 5k, 10k, and Marathon",
            src: karaGoucherPic
        },
        { 
            name: "Ruth Winder",
            olympian: true,
            accomplishment: "Rio 2016 and Tokyo 2021 - Track & Road Cycling",
            src: ruthWinderPic
        },
        { 
            name: "Adam Goucher",
            olympian: true,
            accomplishment: "Sydney 2000 - 5k",
            src: adamGoucherPic
        },
        { 
            name: "Vittoria Lopes",
            olympian: true,
            accomplishment: "Tokyo 2021 - Triathlon",
            src: vittoriaLopesPic
        },
        { 
            name: "Sara Vaughn",
            olympian: false,
            accomplishment: "Chicago Marathon 2023 - Top 3",
            src: saraVaughnPic
        },
        { 
            name: "Drew Hunter",
            olympian: false,
            accomplishment: "2 x US Champ - USA 5k Champ: NYC - 1st place 2022",
            src: drewHunterPic
        },
        { 
            name: "Laura Thweatt",
            olympian: false,
            accomplishment: "Olympica trials 2020 - Marathon 5th place",
            src: lauraThweattPic
        },
        { 
            name: "Sam Appleton",
            olympian: false,
            accomplishment: "70.3 World Champs - 4th place 2017",
            src: samAppletonPic
        },
        { 
            name: "Rudy Von Berg",
            olympian: false,
            accomplishment: "70.3 World Champs - 3rd place",
            src: rudyVonBergPic
        },
        { 
            name: "Taylor Spivey",
            olympian: false,
            accomplishment: "ITU World Triathlon Rank #1",
            src: taylorSpiveyPic
        },
        { 
            name: "Eli Hemming",
            olympian: false,
            accomplishment: "ITU Top Ranked Triathlete with 9 gold medals",
            src: eliHemmingPic
        },
        { 
            name: "Abby Hall",
            olympian: false,
            accomplishment: "UTMB - CCC 2nd Place Female",
            src: abbyHallPic
        },
        { 
            name: "Reed Fischer",
            olympian: false,
            accomplishment: "Boston Marathon 2022 - 5th American",
            src: reedFischerPic
        }

    ]

    return (
      <div className="bg-white mt-2 pt-2 lg:pt-16 pb-8 lg:pb-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">

          <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
            Programming trusted by the world’s leading endurance athletes
          </h2>

          <div className="mx-auto mt-10 grid max-w-lg grid-cols-2 lg:grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            
            {testimonials.map((testimonial, testimonialIndex) => (
                <div key={testimonialIndex} className="rounded-xl overflow-hidden text-center border shadow">

                    {/* Header Image */}
                    <img
                        src={testimonial.src}
                        alt={testimonial.name}
                        className='w-full h-50'
                    />

                    {/* Body */}
                    <div className="p-4 space-y-2 w-full h-50">

                        <div className="text-lg font-semibold">{testimonial.name}</div>

                        {testimonial.olympian && 
                            <img className='h-8 mx-auto' src={olympicRings} alt="Olympic Rings" />
                        }

                        <div className="text-sm text-gray-400">{testimonial.accomplishment}</div>

                    </div>

                </div>
            ))}

          </div>
        </div>
      </div>
    )
  }
  