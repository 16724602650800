import iconLogo from '../media/logos/logo192.png';

import Header from "../components/Header";
import Link from '../components/Link';

export default function TermsOfService(){

    return(
        <div className="bg-white">

            <Header />

            {/* Content */}
            <div className="mx-auto max-w-7xl px-4 lg:px-6 my-8 lg:border-2 lg:rounded-2xl">

                <img className="mx-auto w-32 lg:w-60" src={iconLogo} alt="WhichWeight Icon Logo" />

                <div className="text-center text-lg lg:text-2xl font-bold">WhichWeight Terms Of Use</div>
                <div className="text-center lg:text-xl italic">Last Modified February 13, 2024</div>

                <div className='my-4 lg:my-8 space-y-4'>

                    {/* Acceptance */}
                    <div className='underline'><strong>Acceptance of the Terms of Use</strong></div>
                    <div>These terms of use are entered into by and between WhichWeight, LLC (“WhichWeight,” "Company," "we," or "us"). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, "Terms of Use" or “Terms”), govern your access to and use of <Link url='https://www.whichweight.com'/>, including any content, functionality, and services (collectively, our “Services”). offered on or through <Link url='https://www.whichweight.com'/> (the "Website"), whether as a guest or a registered user</div>
                    <div>Please read the Terms of Use carefully before you start to use the Website. By using the Website or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at <Link url='https://www.whichweight.com/privacy'/>, incorporated herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website.</div>
                    <div>To use the Services, you must be (a) at least 18 years of age or (b) at least 13 years of age and your legal guardian must give WhichWeight, LLC prior express written consent to your use of the Services, as demonstrated by your legal guardian accepting these Terms on your behalf. By using the Services, you represent and warrant that you are of legal age to form a binding contract with us for yourself or your child and that you meet all of the foregoing eligibility requirements. You may not access or use the Services if you are barred from receiving such services under applicable law or have previously been suspended or removed from any of our Services. If you do not meet all of these requirements, you must not use our Services.</div>

                    {/* Terms Changes */}
                    <div className='underline'><strong>Changes to the Terms of Use</strong></div>
                    <div>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them to <Link url='https://www.whichweight.com/terms'/>, and apply to all access to and use of the Website thereafter. However, any changes to the dispute resolution provisions set out in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice before the date the change is posted on the Website.</div>
                    <div>Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.</div>
                    <div>These Terms of Use remain in effect as long as you use any of our Services. </div>

                    {/* Access */}
                    <div className='underline'><strong>Accessing the Website and Account Security</strong></div>
                    <div>We reserve the right to withdraw or amend the Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.</div>
                    <div>You are responsible for both:</div>
                    <div>Making all arrangements necessary for you to have access to the Website.</div>
                    <div>Ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and comply with them.</div>
                    <div>To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete. You agree that all information you provide to register with this Website or otherwise, including, but not limited to, through the use of any interactive features on the Website, is governed by our Privacy Policy at <Link url='https://www.whichweight.com/privacy'/>, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</div>
                    <div>If you choose, or are provided with, a user name, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Website or portions of it using your user name, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</div>
                    <div>We have the right to disable any username, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use. You may terminate these Terms at any time by notifying us at support@whichweight.com and ceasing all use of the Services. If you have an account, you must deactivate your Account by submitting a written request to support@whichweight.com. Once deactivated, your Account data may not be reinstated. If you cancel or otherwise terminate these Terms, you acknowledge that we have the right to and will retain any fees you already paid to us under these Terms.</div>

                    {/* Website Changes */}
                    <div className='underline'><strong>Changes to Website</strong></div>
                    <div>From time to time, we may, in our sole discretion, develop and provide updates to our Services, which may include upgrades, bug fixes, patches, other error corrections, and/or new features (collectively, “Updates”). Updates may also modify or delete in their entirety certain features and functionality. You agree that we have no obligation to provide any Updates or to continue to provide or enable any particular features or functionality.</div>
                    
                    {/* Health and Fitness */}
                    <div className='underline'><strong>Health and Fitness Notices</strong></div>
                    <div>To use our Services, you should be in a good general state of health. If you have knowledge of any pre-existing medical conditions, you should seek medical advice from a doctor before you use our Services. This applies, in particular and without limitation, if you have knowledge of one or more of the following medical complaints/conditions/procedures: cardiovascular disease, lung or respiratory disease (including asthma), spinal and/or joint problems, neuromuscular disease, surgical procedures, or any other health issues.</div>
                    <div>The Services and information offered by WhichWeight and its affiliates do not constitute medical advice or a doctor’s advice. The Services are not a substitute for a medical examination or treatment by a doctor. You should consult your physician before beginning a new fitness program. WhichWeight cannot offer you medical advice or injury prevention, or any other services related to exercise science or the practice of medicine. The Services are not intended to diagnose, treat, cure, or guarantee prevention of any injury or illness. It is your responsibility to consult your doctor before using the Services, engaging in an exercise program, or changing your diet. You are also responsible for taking all necessary precautions to prevent injury to yourself and others while engaging in activities related to your use of our Services. If you experience a medical emergency while using our Services, stop using the Services and consult with a medical professional. WhichWeight is not responsible for any injuries or health problems that may result from your use of the Services, nor from any training programs, competitions, or events you learn about or participate in while using the Services. YOU UNDERSTAND AND AGREE THAT THE SERVICES ARE FOR INFORMATIONAL PURPOSES ONLY AND DO NOT OFFER MEDICAL ADVICE.</div>
                    <div>YOU USE THE SERVICES AT YOUR OWN RISK. Please read and comply with all safety notices that accompany our Services. If you have any doubts about your health, please consult your doctor before starting or continuing to use the Services. WHICHWEIGHT, LLC DISCLAIMS ALL LIABILITY AND RESPONSIBILITY ARISING FROM YOUR RELIANCE ON OR USE OF OUR SERVICES. </div>

                    {/* IP */}
                    <div className='underline'><strong>Intellectual Property Rights</strong></div>
                    <div>The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Company, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</div>
                    <div>These Terms of Use permit you to use the Website for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, except as follows:</div>
                    <div>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</div>
                    <div>You may store files that are automatically cached by your Web browser for display enhancement purposes.</div>
                    <div>You may print or download one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication, or distribution.</div>
                    <div>If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</div>
                    <div>You must not:</div>
                    <div>Modify copies of any materials from this site.</div>
                    <div>Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.</div>
                    <div>Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.</div>
                    <div>You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website. </div>
                    <div>If you wish to make any use of material on the Website other than that set out in this section, please address your request to: support@whichweight.com.</div>
                    <div>If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.</div>

                    {/* Audience */}
                    <div className='underline'><strong>Intended Audience</strong></div>
                    <div>This Website is intended for individuals who are (a) at least 18 years of age or (b) at least 13 years of age have received the express written consent of your legal guardian to use the website. This Website is not intended for any children under the age of 13.</div>

                    {/* Trademarks */}
                    <div className='underline'><strong>Trademarks</strong></div>
                    <div>The Company name, the terms, the Company logo, and all related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs, and slogans on this Website are the trademarks of their respective owners.</div>

                    {/* Prohibited Uses */}
                    <div className='underline'><strong>Prohibited Uses</strong></div>
                    <div>You may use the Website only for lawful purposes and in accordance with these Terms of Use. You shall not use the Website:</div>
                    <div>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</div>
                    <div>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</div>
                    <div>To send, knowingly receive, upload, download, use, or re-use any material that does not comply with these Terms of Use.</div>
                    <div>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</div>
                    <div>To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses associated with any of the foregoing).</div>
                    <div>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of the Website, or expose them to liability.</div>
                    <div>Additionally, you shall not:</div>
                    <div>Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Website, including their ability to engage in real time activities through the Website.</div>
                    <div>Use any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.</div>
                    <div>Use any manual process to monitor or copy any of the material on the Website, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</div>
                    <div>Use any device, software, or routine that interferes with the proper working of the Website.</div>
                    <div>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</div>
                    <div>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website.</div>
                    <div>Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</div>
                    <div>Otherwise attempt to interfere with the proper working of the Website.</div>

                    {/* Copyright Infringement */}
                    <div className='underline'><strong>Copyright Infringement</strong></div>
                    <div>If you believe that any contents of the website violate your copyright, please email support@whichweight.com. It is the policy of the Company to terminate the user accounts of repeat infringers, which the Company will determine in its sole discretion.</div>

                    {/* Visitor Info */}
                    <div className='underline'><strong>Information About You and Your Visits to the Website</strong></div>
                    <div>All information we collect on this Website is subject to our Privacy Policy at <Link url='https://www.whichweight.com/privacy'/>. By using the Website, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy. </div>

                    {/* Fees */}
                    <div className='underline'><strong>Fees and Payment</strong></div>
                    <div>You are responsible for all charges and fees associated with your use of the Services, including but not limited to all wireless and/or internet service provider fees, devices and equipment, sales taxes, and any other fees and charges necessary to access the Services. We offer the Services using fees based on specified time periods of subscribed-to Services. You agree to pay all fees associated with the Services you select at the time of signup. You are responsible for payment of any taxes, fees, or other charges associated with your use of the Services and associated fees. We reserve the right to change our subscription plans and features offered or to adjust our fees or any components thereof in any manner and at any time.</div>
                    <div>To pay any fee, you must designate and provide information about your preferred payment method (e.g., credit card, online payment service, a third party, like Apple Pay or Google Play, or any other payment method made available by WhichWeight) (the "Payment Method"). If you provide your payment information, you authorize us and certain third-party service providers, payment card networks and payment processors to receive, store and encrypt your payment information. No refunds or credits will be provided by WhichWeight, other than as set forth in these Terms. If your payment provider determines that there are fraudulent charges on your account resulting from use of your Payment Method at the Services, please contact us immediately via support@whichweight.com. If you do not pay on time or if we cannot charge the payment method you have on file for any reason, we reserve the right to either suspend or terminate your access to the Services. If your unpaid fees are referred to an attorney or collections agency, you shall pay all reasonable attorney’s fees or collections agency fees. If you access our Services through a third-party subscription, your use of the Services is also subject to the third-party’s terms and conditions and your access to the Services is subject to our receipt of payment from such third party. WhichWeight reserves the right to terminate your use of the Services if your payment via a third-party subscription is not received for any reason.</div>
                    
                    {/* Refunds */}
                    <div className='underline'><strong>Refund Policy</strong></div>
                    <div>All sales are final, and the Company does not offer any money-back guarantees. You recognize and agree that you shall not be entitled to a refund for any purchase.</div>

                    {/* Links */}
                    <div className='underline'><strong>Linking to the Website</strong></div>
                    <div>You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express, written consent.</div>
                    <div>You may use these features solely as they are provided by us and solely with respect to the content they are displayed with, and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:</div>
                    <div>Establish a link from any website that is not owned by you.</div>
                    <div>Cause the Website or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking.</div>
                    <div>Link to any part of the Website other than the homepage.</div>
                    <div>Otherwise take any action with respect to the materials on this Website that is inconsistent with any other provision of these Terms of Use.</div>
                    <div>The website from which you are linking, or on which you make certain content accessible, must comply in all respects in these Terms of Use.</div>
                    <div>You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We reserve the right to withdraw linking permission without notice. </div>

                    {/* Links Cont. */}
                    <div className='underline'><strong>Links from the Website</strong></div>
                    <div>If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</div>

                    {/* Location */}
                    <div className='underline'><strong>Geographic Restrictions</strong></div>
                    <div>The owner of the Website is based in the State of Colorado in the United States. If you access the Website from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</div>

                    {/* Warranties */}
                    <div className='underline'><strong>Disclaimer of Warranties</strong></div>
                    <div>TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</div>
                    <div>YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</div>
                    <div>TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</div>
                    <div>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</div>

                    {/* Liability */}
                    <div className='underline'><strong>Limitation on Liability</strong></div>
                    <div>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, OR THE FAILURE TO DELETE ANY CONTENT ON THE WEBSITE, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, HEALTH OR MEDICAL ISSUES, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. </div>
                    <div>THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</div>

                    {/* Indemnification */}
                    <div className='underline'><strong>Indemnification</strong></div>
                    <div>You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, any use of the Website’s content, services, and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the Website.</div>

                    {/* Jurisdication */}
                    <div className='underline'><strong>Governing Law and Jurisdiction</strong></div>
                    <div>All matters relating to the Website and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Colorado without giving effect to any choice or conflict of law provision or rule (whether of the State of Colorado or any other jurisdiction).</div>
                    <div>Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Website shall be instituted exclusively in the federal courts of the United States or the courts of the State of Colorado, in each case located in the City of Denver and County of Denver, You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</div>

                    {/* Claims */}
                    <div className='underline'><strong>Limitation on Time to File Claims</strong></div>
                    <div>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED TO THE EXTENT PERMITTED UNDER LAW.</div>

                    {/* Severability */}
                    <div className='underline'><strong>Waiver and Severability</strong></div>
                    <div>No waiver by the Company of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</div>
                    <div>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect. </div>

                    {/* Agreement */}
                    <div className='underline'><strong>Entire Agreement</strong></div>
                    <div>The Terms of Use and our Privacy Policy constitute the sole and entire agreement between WhichWeight, LLC regarding the Website and Services and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Website.</div>

                    {/* Comments */}
                    <div className='underline'><strong>Your Comments and Concerns</strong></div>
                    <div>This website is operated by WhichWeight, LLC in Denver, Colorado, United States.</div>
                    <div>All feedback, comments, requests for technical support, and other communications relating to the Website should be directed to: support@whichweight.com.</div>

                </div>

            </div>

        </div>
    );

}