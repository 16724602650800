// From ChatGPT
// This provides the context for our modal, allowing any component in our app to show or hide the modal.

import React, { createContext, useState } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {

  const [modalInfo, setModalInfo] = useState(null);

  const showModal = (modalInfo) => { setModalInfo(modalInfo); };

  const hideModal = () => { setModalInfo(null); };

  return (
    <ModalContext.Provider value={{ modalInfo, showModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  );
};