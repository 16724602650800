import { Dialog } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'

export default function ActionModal({ modalInfo, hideModal, handleButtonAction }) {
    return (
      <div>

        {/* Hide Button */}
        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  
            <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                onClick={() => hideModal()}
            >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>

        </div>

        <div className="sm:flex sm:items-start">

            {modalInfo.type === "waitlist-failure" && 
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-500 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
            }

            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">

                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    {modalInfo.title}
                </Dialog.Title>

                <div className="mt-2">
                    <p className="text-sm text-gray-500">
                    {modalInfo.body}
                    </p>
                </div>

            </div>
        </div>

        {/* Action Button */}
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                onClick={() => hideModal()}
            >
                {modalInfo.buttonText}
            </button>

            {/*
            <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={() => hideModal()}
            >
            Cancel
            </button>
            */}

        </div>
      </div>
    )
  }
  