import SafariLogo from '../../media/addToHomeScreen/SafariLogo.png'
import SafariShareIconLocation from '../../media/addToHomeScreen/SafariShareIconLocation.png'
import SafariAddToHomeScreenLocation from '../../media/addToHomeScreen/SafariAddToHomeScreenLocation.png'

export default function IOSAddToHomeScreenInstructions() {
 
    return(
        <>
            {/* Step 1 */}
            <div className='space-y-2 bg-gray-200 p-4 rounded-lg'>
                <div className='text-gray-600'>Step 1</div>
                <div className='flex items-center gap-x-2 text-xl'>
                    <div>Open</div>
                    <div className='flex items-center gap-x-2 bg-white px-2 rounded-full'>
                        <img src={SafariLogo} className='h-5' />
                        <div>Safari</div>
                    </div>
                </div>
            </div>

            {/* Step 2 */}
            <div className='space-y-2 bg-gray-200 p-4 rounded-lg'>
                <div className='text-gray-600'>Step 2</div>
                <div className='flex items-center gap-x-1 text-xl'>
                    <div>Go to </div>
                    <a href='https://app.whichweight.com/' className="text-blue-600 hover:text-blue-800 underline">app.whichweight.com</a>  
                </div>
            </div>

            {/* Step 3 */}
            <div className='space-y-2 bg-gray-200 p-4 rounded-lg'>
            <div className='text-gray-600'>Step 3</div>
                <div className='text-xl'>Press the Share button located at the bottom of the screen</div>
                <img src={SafariShareIconLocation} className='rounded-lg lg:h-32' />
            </div>

            {/* Step 4 */}
            <div className='space-y-2 bg-gray-200 p-4 rounded-lg'>
            <div className='text-gray-600'>Step 4</div>
                <div className='text-xl'>Select Add to Home Screen</div>
                <img src={SafariAddToHomeScreenLocation} className='rounded-lg' />
            </div>

        </>
    );

}