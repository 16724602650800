import { EnvelopeIcon } from "@heroicons/react/20/solid";

import Header from "../components/Header";
import Footer from "../components/Footer";

import ChrisPic from '../media/profile_pics/Chris.png';
import JacobPic from '../media/profile_pics/Jacob.png';

const people = [
    {
        name: 'Chris Lee',
        role: 'Co-Founder / CEO',
        image: ChrisPic,
        bio: [
            'From coaching Olympians to leading innovation in athletic performance, Chris has always been driven by a single mission: to elevate the standards of training and conditioning. His journey began as a strength coach for world-class athletes, where he quickly realized the limitations of traditional training methods.',
            'As the Director of Athletic Performance for the Adidas Pro Running Team and later as the Head of Strength & Conditioning for Axgen Genetics, Chris began to envision a new paradigm. One where technology and data could make training more precise, effective, and individualized.',
            'This vision led to the creation of WhichWeight. A platform that leverages years of expertise and patent-pending algorithms to provide safe, accurate, and dynamic weight recommendations for athletes.',
            'With WhichWeight, Chris aims to make the guesswork in athletic training a thing of the past, allowing coaches and athletes to focus on what truly matters: performance and progress.'
        ],
        linkedinUrl: 'https://www.linkedin.com/in/chris-lee-75b448196/',
        twitterUrl: '#',
        email: 'chris@whichweight.com'
    },
    {
        name: 'Jacob Carlson',
        role: 'Co-Founder / CTO',
        image: JacobPic,
        bio: [
            'Jacob has always had a passion for creation. Whether it is working on side projects in his free time for fun or working full-time at start-ups trying to solve difficult problems, he loves to learn new things and challenge himself.',
            'He also enjoys getting away from his computer and being active. While earning his Computer Science degree at CU Boulder he would take advantage of the numerous outdoor activities available. Jacob has always made it a priority in his life to remain fit and now spends most of his time in Denver where he can be found training for the next marathon, triathlon, or any other endurance challenge he wants to face next.',
            'With WhichWeight, Jacob has the opportunity to combine two of his passions, creation and physical activity, to help people become the best versions of themselves.'
        ],
        linkedinUrl: 'https://www.linkedin.com/in/jacobcarlson92599/',
        twitterUrl: 'https://twitter.com/imjncarlson',
        email: 'jacob@whichweight.com'
    }
]
  
export default function AboutUs() {
    return (
        <div className='flex flex-col min-h-screen'>

            {/* Background grid pattern */}
            <svg className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(125%_150%_at_top_right,white,transparent)]" aria-hidden="true">
                <defs>
                <pattern id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" width={200} height={200} x="50%" y={-1} patternUnits="userSpaceOnUse">
                    <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
                </defs>
                <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
            </svg>

            <Header />

            <div>
                        
                <div className="px-3 lg:px-0 mx-auto max-w-7xl gap-x-8 lg:gap-y-20 mb-10">
                
                    {/* Gradient Text */}
                    <h1 className="mt-4 lg:mt-12 lg:pb-4 mb-6 lg:mb-8 text-center text-4xl lg:text-5xl font-bold text-gray-900 tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-orange-600 to-orange-400">
                        Meet the <br className="block sm:hidden" />WhichWeight Team
                    </h1>

                    <ul role="list" className="space-y-6 lg:space-y-12 divide-gray-200 xl:col-span-3">
                        {people.map((person) => (
                            <li key={person.name} className="flex flex-col gap-8 border rounded-3xl bg-white shadow p-4 sm:flex-row ">
                            <img className="aspect-[4/5] h-72 flex-none rounded-2xl object-cover" src={person.image} alt="" />
                            <div className="flex-auto">
                                <h3 className="text-2xl font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                                <p className="text-base leading-7 text-gray-600">{person.role}</p>

                                {person.bio.map((line, index) => (
                                    <p key={index} className="mt-6 text-base leading-7 text-gray-600">{line}</p>
                                ))}
                                <ul role="list" className="mt-6 mb-4 flex gap-x-2">
                                
                                    {person.linkedinUrl !== "#" &&
                                        <li>
                                            <a href={person.linkedinUrl} className="text-gray-400 flex hover:text-gray-500">
                                                <span className="sr-only">LinkedIn</span>
                                                <svg className="h-4 w-4 mt-1.5 mr-2" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                                    <path
                                                    fillRule="evenodd"
                                                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                    clipRule="evenodd"
                                                    />
                                                </svg>
                                            </a>
                                        </li>
                                    }

                                    {person.twitterUrl !== "#" &&
                                        <li>
                                            <a href={person.twitterUrl} className="text-gray-400 flex hover:text-gray-500">
                                            <span className="sr-only">Twitter</span>
                                                <svg className="h-4 w-4 mt-1.5 mr-1.5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                            </svg>
                                            </a>
                                        </li>
                                    }

                                    <div className="flex">
                                        <EnvelopeIcon className="mt-1 mr-2 h-5 text-gray-400" />
                                        <li className="text-gray-400">{person.email}</li>
                                    </div>


                                </ul>
                            </div>
                            </li>
                        ))}
                    </ul>

                </div>
            </div>
            
            <Footer />

        </div>
    )
}
  