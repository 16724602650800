import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';

import textLogo from '../media/logos/text_logo.png'
import iconLogo from '../media/logos/logo192.png';

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'About Us', href: '/about' }
]

export default function Header() {

  // Function used to navigate to new pages
  let navigate = useNavigate();
  const OnNavigationButtonPressed = (route, props) => { navigate(route, props); };

  const location = useLocation();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="inset-x-0 top-0 bg-gray-100 shadow-md mb-1">

    {/* Desktop Nav Bar */}
    <nav className="flex items-center py-4 px-4 gap-x-2 lg:gap-x-0 lg:px-8" aria-label="Global">
      
      {/* Icon Logo */}
      <div className="flex lg:mr-4">
        <a href="/">
          <span className="sr-only">WhichWeight</span>
          <img className="h-8 lg:h-12 w-auto" src={iconLogo} alt="WhichWeight Icon Logo" />
        </a>
      </div>

      {/* Text Logo */}
      <div className="flex lg:flex-1">
        <a href="/" className="pt-1 lg:pt-1.5">
          <span className="sr-only">WhichWeight</span>
          <img className="h-6 lg:h-8 w-auto" src={textLogo} alt="WhichWeight Icon Logo" />
        </a>
      </div>

      <div className='flex-grow' />

      {/* Mobile Navbar pull up button */}
      <div className="flex lg:hidden">
        <button
          type="button"
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      
      {/* Nav Options */}
      <div className="hidden lg:flex lg:gap-x-4">
        {navigation.map((item) => (
          <a key={item.name} href={item.href} className={item.href === location.pathname ? "text-lg font-semibold leading-6 text-gray-900 rounded-lg px-4 py-2 bg-gray-200" : "text-lg font-semibold leading-6 text-gray-900 rounded-lg px-4 py-2"}>
            {item.name}
          </a>
        ))}
      </div>
      
      {/* Log In Button */} 
      <div 
        className="ml-4 hidden lg:flex cursor-pointer"
        onClick={() => OnNavigationButtonPressed('/getapp')}
      >

        <div className="flex items-center gap-x-2 text-white text-lg font-semibold leading-6 text-gray-900 rounded-full px-6 py-2 bg-gradient-to-r from-orange-600 to-orange-400">
          <div>Get App</div>
          {/*<ArrowDownTrayIcon className='h-4' />*/}
        </div>

      </div>
      
      
    </nav>

    {/* Mobile Nav Bar */}
    <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
      
      <div className="fixed inset-0 z-50" />
      
      <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        
        <div className="flex items-center justify-between">
          
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">WhichWeight</span>
            <img className="h-8 lg:h-12 w-auto" src={iconLogo} alt="WhichWeight Icon Logo" />
          </a>

          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(false)}
          >
            <span className="sr-only">Close menu</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>

        </div>

        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {item.name}
                </a>
              ))}
            </div>

          </div>
        </div>
      </Dialog.Panel>

    </Dialog>

  </header>

  )
}
