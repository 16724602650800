import React, { useContext } from 'react';
import { ModalContext } from './ModalContext';
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import ActionModal from './ActionModal';

const ModalOverlay = () => {

  const { modalInfo, hideModal } = useContext(ModalContext);

  if (modalInfo === null) return null;

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={hideModal}>

        {/* Background Blur */}
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                
                {modalInfo.type === "waitlist-success" && <ActionModal modalInfo={modalInfo} hideModal={hideModal} />}

                {modalInfo.type === "waitlist-failure" && <ActionModal modalInfo={modalInfo} hideModal={hideModal} />}

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )

};

export default ModalOverlay;
