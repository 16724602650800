import GoogleChromeLogo from '../../media/addToHomeScreen/GoogleChrome.png'
import AndroidSettingsButtonLocation from '../../media/addToHomeScreen/AndroidSettingsButtonLocation.png'
import AndroidInstallButtonLocation from '../../media/addToHomeScreen/AndroidInstallButtonLocation.png'

export default function AndroindAddToHomeScreenInstructions() {
 
    return(

        <>
            {/* Step 1 */}
            <div className='space-y-2 bg-gray-200 p-4 rounded-lg'>
                <div className='text-gray-600'>Step 1</div>
                <div className='flex items-center gap-x-2 text-xl'>
                    <div>Open</div>
                    <div className='flex items-center gap-x-2 bg-white px-2 rounded-full'>
                        <img src={GoogleChromeLogo} className='h-5' />
                        <div>Google Chrome</div>
                    </div>
                </div>
            </div>

            {/* Step 2 */}
            <div className='space-y-2 bg-gray-200 p-4 rounded-lg'>
                <div className='text-gray-600'>Step 2</div>
                <div className='flex items-center gap-x-1 text-xl'>
                    <div>Go to </div>
                    <a href='https://app.whichweight.com/' className="text-blue-600 hover:text-blue-800 underline">app.whichweight.com</a>  
                </div>
            </div>

            {/* Step 3 */}
            <div className='space-y-2 bg-gray-200 p-4 rounded-lg'>
            <div className='text-gray-600'>Step 3</div>
                <div className='text-xl'>Press the Settings button located at the top of the screen</div>
                <img src={AndroidSettingsButtonLocation} className='rounded-lg lg:h-24' />
            </div>

            {/* Step 4 */}
            <div className='space-y-2 bg-gray-200 p-4 rounded-lg'>
            <div className='text-gray-600'>Step 4</div>
                <div className='text-xl'>Select Install App</div>
                <img src={AndroidInstallButtonLocation} className='rounded-lg' />
            </div>

        </>

        
    );

}