import iconLogo from '../media/logos/logo192.png';

import Header from "../components/Header";
import Footer from '../components/Footer';

export default function Wavier(){

    return(
        <div className="bg-white">

            <Header />

                {/* Content */}
                <div className="mx-auto max-w-7xl px-4 lg:px-6 my-8 lg:border-2 lg:rounded-2xl">

                    <img className="mx-auto w-32 lg:w-60" src={iconLogo} alt="WhichWeight Icon Logo" />

                    <div className="text-center text-lg lg:text-2xl font-bold">WhichWeight Fitness Services Wavier</div>
                
                    <div className='my-4 lg:my-8 space-y-4'>
                        
                        <div>I consent that I will be voluntarily participating in the Fitness Services that will be conducted by the Fitness Provider. These Fitness Services will include, but not be limited to the following:</div>

                        <div className='px-4 lg:px-20'>Strength Training, Online Strength Training Programs, Personal Training, Sports Performance Training, Weight Lifting, Running Training, Cardio Training Programs, Mobility Programs, General Fitness</div>

                        <div>I also consent to waive certain legal rights, including the right to sue the following party, and, if applicable, its owners, trainers, representatives, and facilities from any physical, material, tangible or intangible, loss or damages that may happen to me during my participation in any of the fitness services (hereinafter, "Fitness Services") undertaken while under their instruction or thereafter: WhichWeight and all acting employees of WhichWeight (the "Fitness Provider").</div>

                        <div>The following is the identifying and contact information of the Fitness Provider:</div>

                        <div className='lg:px-20 space-y-4'>
                            <div>Business Name: WhichWeight</div> 
                            <div>Business Address: 755 11th St Boulder, CO 80302</div>
                            <div>Business Contact Number: 9732949262</div>
                        </div>

                        <div>I agree and understand the following:</div>
                        
                        <div className='lg:px-10 space-y-4'>
                            <div>It is my responsibility to consult a physician before participating in this or any fitness program and I affirm that I have no medical conditions that would restrict me from participating in any of the Fitness Services.</div>
                            <div>I agree to hold the Fitness Provider, and if applicable, its owners, trainers, and representatives, harmless from any damage, whether tangible or intangible, that may happen to me while participating in the Fitness Services. Such injuries may include, but are not limited to, muscle strains, muscle sprains, muscle spasms, heart attacks, raised blood pressure, and broken, fractured, or dislocated bones.</div>
                            <div>I agree that the Fitness Provider offers the Fitness Services with no guarantee of results. I agree that I am solely responsible to maintain the diet and fitness regime appropriate for my level of health and stamina, and I agree that any results that occur, whether positive or negative, are the effects of my own personal choices.</div>
                            <div>I agree that participation in the Fitness Services is not a replacement for actual medical care, and that if I do experience medical issues, I will contact my doctor immediately.</div>
                            <div>I agree and verify that all of the information that I have given the Fitness Provider and its representatives is accurate, up-to-date, and without the omission of any known medical issues.</div>
                            <div>I agree and verify that If I have omitted any necessary personal information, whether knowingly or unknowingly, I will hold the Fitness Provider harmless against all liability for any damages that may occur to myself or to others because of my actions or inactions.</div>
                            <div>I agree to keep the Fitness Provider apprised of any changes or upcoming changes concerning my physical health and personal information.</div>
                            <div>I understand and agree that it is my responsibility to let the Fitness Provider know if I find myself in any pain or discomfort before, after, or during the Fitness Services.</div>
                            <div>If I do require medical treatment or attention while or after participating in the Fitness Services, I agree that the medical costs are mine and mine alone and hold the Fitness Provider blameless from any charges, fees, or costs that my conditions may incur.</div>
                        </div>

                        <div>This Fitness Services Waiver will bind and be enforceable against me and all of my personal representatives. I agree that this Fitness Services Waiver should be enforceable to the fullest extent of the law, and if any portion is held invalid, the remainder should continue in full legal force and effect.</div>

                        <div>I specifically acknowledge and agree that this document is not intended to be a general release, which would be limited under some state and local laws.</div>

                        <div>This Fitness Services Waiver shall be construed and interpreted as broadly as possible in the applicable jurisdiction.</div>

                        <div><strong>ASSUMPTION OF RISK.</strong> I understand and am aware that my participation in the Fitness Services involves risks. These risks may lead to tangible or intangible harm, and I agree that they may result not only from my own actions but also from the actions of others. With the knowledge and understanding of these risks, I choose, of my own will and volition, to continue participating in the Fitness Services. I am also aware that there are risks that I may not have considered, yet I waive my right to any claims that may occur from these unconsidered risks and I choose, of my own will and volition, to participate in the Fitness Services.</div>

                        <div><strong>COVENANT NOT TO SUE.</strong> I will not start any lawsuit or other court action against the Fitness Provider, nor will I join any such proceeding, including any claim for money damages. I acknowledge and agree that I am entering a covenant not to sue the Fitness Provider in any capacity, including to hold the Fitness Provider liable for any injury, loss, or damage sustained by me or my property, even if it is due to the Fitness Provider's negligence or omission. I also waive the right of any of my insurers' to make any such claim.</div>

                        <div><strong>INDEMNIFICATION</strong> I agree to defend and indemnify the Fitness Provider and any of its affiliates (if applicable) and hold them harmless against any and all legal claims and demands, including reasonable attorney's fees, which may arise from or relate to my use or misuse of the Fitness Services or my conduct or actions. I agree that the Fitness Provider shall be able to select its own legal counsel and may participate in its own defense, if desired.</div>

                        <div><strong>REPRESENTATION</strong> I am over 18 (eighteen) years of age, and am medically and physically able to participate in the Fitness Services.</div>

                        <div><strong>GOVERNING LAW</strong> This Fitness Services Waiver shall be governed by and construed in accordance with the internal laws of Colorado without giving effect to any choice or conflict of law provision or rule. Each party irrevocably submits to the exclusive jurisdiction and venue of the federal and state courts located in the following county in any legal suit, action, or proceeding arising out of or based upon this Fitness Services Waiver: Boulder.</div>

                        <div>I have read the above Fitness Services Waiver fully and I understand and agree to its contents. I understand and agree that by signing this Fitness Services Waiver I forfeit any right, claim, or ability to hold the Fitness Provider responsible for any tangible or intangible damages, loss of property, or loss of life that may occur during or after my use of the facilities and participation in the Fitness Services.</div>

                    </div>

                </div>

            <Footer />
            
        </div>
    );

}