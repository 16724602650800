import { useState } from 'react';
import iconLogo from '../media/logos/logo192.png';
import AppleIcon from '../media/addToHomeScreen/Apple.png';
import AndroidIcon from '../media/addToHomeScreen/Android.png';

import IOSAddToHomeScreenInstructions from '../components/AddToHomeScreen/IOSAddToHomeScreenInstrcutions';
import AndroindAddToHomeScreenInstructions from '../components/AddToHomeScreen/AndroidAddToHomeScreenInstructions';

import Header from "../components/Header";
import Footer from "../components/Footer";

export default function GetApp(){

    const tabs = [
        { name: 'Apple', icon: AppleIcon },
        { name: 'Android', icon: AndroidIcon }
    ]
      
    function classNames(...classes) { return classes.filter(Boolean).join(' '); }

    const [selectedTab, setSelectedTab] = useState('Apple');

    return(
        <div className="bg-white">

            <Header />
            
            {/* Content */}
            <div className="mx-auto max-w-7xl px-4 lg:px-6 my-8 ">
                
                <div 
                    className='mx-auto flex items-center justify-center p-4 w-60 h-60 lg:w-80 lg:h-80 border-2 rounded-3xl shadow-lg cursor-pointer'
                    onClick={() => window.open('https://app.whichweight.com/', '_blank', 'noopener,noreferrer')}
                >
                    <img className='w-40 h-40 lg:w-60 lg:h-60' src={iconLogo} alt="WhichWeight Icon Logo" />
                </div>

                <div className="mt-5 lg:mt-10 text-center text-lg lg:text-3xl">WhichWeight is available at</div>

                <div 
                    className="text-center underline text-blue-500 lg:mt-2 text-lg lg:text-3xl font-bold cursor-pointer"
                    onClick={() => window.open('https://app.whichweight.com/', '_blank', 'noopener,noreferrer')}
                >
                    app.whichweight.com
                </div>


                <div className="mt-6 lg:mt-12 text-center text-base lg:text-2xl">
                    How To Add to your Phone's Home Screen
                </div>

                <div className='mt-4 mx-auto max-w-5xl border border-gray-600 rounded-2xl'>
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <a
                                    key={tab.name}
                                    onClick={() => { setSelectedTab(tab.name) }}
                                    className={classNames(
                                        tab.name === selectedTab
                                            ? 'border-gray-600 text-gray-500'
                                            : 'border-gray-300 text-gray-500',
                                        'w-1/2 border-b-2 py-4 px-1 text-center font-medium flex justify-center items-center gap-x-2 cursor-pointer'
                                    )}
                                >
                                    <img src={tab.icon} alt={tab.name} className="w-6" />
                                    <div>{tab.name}</div>
                                </a>
                            ))}
                        </nav>
                    </div>

                    <div className="p-4 space-y-4">

                        {selectedTab === 'Apple' && <IOSAddToHomeScreenInstructions />}

                        {selectedTab === 'Android' && <AndroindAddToHomeScreenInstructions />}

                    </div>

                </div>

            </div>

            <Footer />

        </div>
    );

}